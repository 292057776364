import React, { useState, useEffect } from "react"
// import useAdminData from "../custom-hooks/useAdminData"
// import AdminPanel from "../components/admin-panel/AdminPanel"
// import AdminLogin from "../components/admin-login/AdminLogin"
// import IdeaInfo from "../components/admin-panel/idea-info/IdeaInfo"
// import InitiativeInfo from "../components/admin-panel/initiative-info/InitiativeInfo"
// import Button from "../components/UI/custom-button/CustomButton"
// import { useStaticQuery, graphql } from "gatsby"
// import * as Style from "./admin.module.scss"
import { navigate } from "gatsby"

// const query = graphql`
//   query {
//     allStrapiArea {
//       nodes {
//         id
//         area_bg
//         area_en
//         post_code
//         pop
//       }
//     }
//   }
// `

const Admin = () => {
  useEffect(() => {
    navigate("/")
  }, [])
  // const {
  //   getIdeahandler,
  //   setToken,
  //   token,
  //   setAdminKey,
  //   ideaData,
  //   initiativeData,
  // } = useAdminData()

  // const data = useStaticQuery(query)
  // const areaData = data.allStrapiArea

  // const [selection, setSelection] = useState(null)

  // const slectionHandler = selection => {
  //   setSelection(selection)
  // }

  // console.log(ideaData)
  // console.log(initiativeData)

  // return (
  //   <div className={Style.adminMainWrapper}>
  //     <h1 className={Style.adminHeading}>ADMIN PANEL</h1>
  //     {!token && <AdminLogin setToken={setToken} />}
  //     {token && !ideaData && !initiativeData && (
  //       <AdminPanel
  //         token={token}
  //         setAdminKey={setAdminKey}
  //         getIdeahandler={getIdeahandler}
  //       />
  //     )}
  //     {ideaData && initiativeData && (
  //       <div className={Style.adminSelectionWrapper}>
  //         <Button
  //           buttonHandler={slectionHandler.bind(this, "idea")}
  //           global={true}
  //           style={Style.adminButton}
  //         >
  //           Идеи
  //         </Button>
  //         <Button
  //           buttonHandler={slectionHandler.bind(this, "initiative")}
  //           global={true}
  //           style={Style.adminButton}
  //         >
  //           Инициативи
  //         </Button>
  //       </div>
  //     )}
  //     {selection === "idea" && <IdeaInfo areaData={areaData} data={ideaData} />}
  //     {selection === "initiative" && <InitiativeInfo data={initiativeData} />}
  //   </div>
  // )

  return null
}

export default Admin
